import moment from 'moment';
import { select, put, takeLatest, call, delay } from 'redux-saga/effects';
import { patchPassword, verifyToken, refreshToken } from 'api';
import {
  AUTH_REFRESH_REQUEST,
  AUTH_SIGNOUT_REQUEST,
  GET_AUTH_STATE,
  CHANGE_PASSWORD_REQUEST,
  AUTH_SIGNIN_REQUEST,
  AUTH_REFRESH_TOKEN,
} from 'redux-api/action/actionTypes';
import * as actions from 'redux-api/action/auth';
import selectAuth from 'redux-api/reselect/auth';

const selectLastRefresh = (state) => state.auth.lastRefresh;

function* signinSaga({ payload }) {
  const { pathname, query, ...rest } = payload;
  try {
    const { data } = yield call(verifyToken, payload.accessToken, 'signinSaga');

    yield put(actions.signinSuccess({ ...data, signInUserSession: rest, pathname, query }));
  } catch (err) {
    yield put(actions.signinFailure({ err, pathname }));
  }
}

function* signoutSaga() {
  try {
    yield put(actions.signoutSuccess());
  } catch (err) {
    yield put(actions.signinFailure(err));
  }
}

function* getAuthStateSaga({ payload }) {
  try {
    const {
      signInUserSession: { accessToken },
    } = yield select(selectAuth);

    if (!accessToken) {
      yield delay(3000);
      throw new Error('401 - Unauthorized');
    }

    yield call(verifyToken, accessToken, 'getAuthStateSaga');

    yield put(actions.signedSuccess());
  } catch (err) {
    yield put(actions.refreshToken({ pathname: payload.pathname }));
  }
}

function* refreshTokenSaga({ pathname }) {
  try {
    const {
      signInUserSession: { refreshToken: token },
    } = yield select(selectAuth);

    const { data } = yield call(refreshToken, token);

    yield put(actions.updateToken(data));

    return yield put(actions.signedSuccess());
  } catch (err) {
    return yield put(actions.signedFailure({ err, pathname }));
  }
}

function* refreshAuthSaga() {
  try {
    const now = moment();
    const lastRefresh = yield select(selectLastRefresh);
    const maxRefreshDuration = process.env.REFRESH_TOKEN_DURATION;

    // if (!lastRefresh) return yield put(actions.refreshSkip());

    if (lastRefresh) {
      const diff = now.diff(lastRefresh, 'minutes');
      if (diff < maxRefreshDuration) {
        return yield put(actions.refreshSkip());
      }
    }

    const {
      signInUserSession: { refreshToken: token },
    } = yield select(selectAuth);

    const { data } = yield call(refreshToken, token);

    yield put(actions.updateToken(data));

    return yield put(actions.refreshSuccess());
  } catch (err) {
    return yield put(actions.refreshFailure(err));
  }
}

function* changePasswordSaga({ payload: { password, callback } }) {
  try {
    const { signInUserSession, adminRequestedStreetlibInternalId } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    yield call(patchPassword, accessToken, adminRequestedStreetlibInternalId, { password });

    yield put(actions.changePasswordSuccess());
    callback();
  } catch (err) {
    if (err.response) {
      if (err.response.data.errors) {
        yield put(actions.changePasswordFailure(err.response.data.errors[0]));
      } else {
        yield put(actions.changePasswordFailure(err.response.data));
      }
    } else {
      yield put(actions.changePasswordFailure(err));
    }
  }
}

export default function* watchAuth() {
  yield takeLatest(AUTH_REFRESH_TOKEN, refreshTokenSaga);
  yield takeLatest(AUTH_REFRESH_REQUEST, refreshAuthSaga);
  yield takeLatest(AUTH_SIGNIN_REQUEST, signinSaga);
  yield takeLatest(AUTH_SIGNOUT_REQUEST, signoutSaga);
  yield takeLatest(GET_AUTH_STATE, getAuthStateSaga);
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordSaga);
}