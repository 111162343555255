// import moment from 'moment';
import * as Sentry from '@sentry/nextjs';
import { takeLatest, takeEvery, select, call, put, delay } from 'redux-saga/effects';
import {
  deleteAvatar,
  patchAvatar,
  getTokenActivation,
  patchProfile,
  getProfile,
  getProfileOnboard,
  deleteNewsletter,
  postNewsletter,
  postAcceptContract,
} from 'api';
import {
  GET_PROFILE_REQUEST,
  PATCH_PROFILE_REQUEST,
  NATIVE_LOCAL_AUTH,
  AUTH_SIGNIN_SUCCESS,
  TOKEN_ACTIVATION_REQUEST,
  SET_USER_IMPERSONATION,
  PATCH_AVATAR_REQUEST,
  DELETE_AVATAR_REQUEST,
  DELETE_AVATAR_SUCCESS,
  POST_NEWSLETTER_REQUEST,
  DELETE_NEWSLETTER_REQUEST,
  TOKEN_ACTIVATION_SUCCESS,
  AUTH_SIGNED_SUCCESS,
  PATCH_LOCALIZATION_REQUEST,
  POST_ACCEPT_CONTRACT_REQUEST,
  SET_LANGUAGE,
} from 'redux-api/action/actionTypes';
import selectAuth from 'redux-api/reselect/auth';
import * as profileActions from 'redux-api/action/profile';
import * as nativeActions from 'redux-api/action/native';
import { openFeedback } from 'redux-api/action/feedback';
import Router from 'next/router';
import translations from 'translations';

const selectProfile = (state) => state.profile;
const selectConfig = (state) => state.config;

function sendToNative(action) {
  const isServer = typeof window === 'undefined';

  if (!isServer && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(action));
  }
}

function* getProfileSaga({ type }) {
  const { languageCode } = yield select(selectConfig);

  try {
    const {
      payload: { guid },
    } = yield select(selectProfile);

    if (type === AUTH_SIGNED_SUCCESS && guid) return yield put(profileActions.getProfileCached());

    const { attributes, signInUserSession, adminRequestedStreetlibInternalId } = yield select(
      selectAuth,
    );

    // if (!attributes['custom:internal_id']) return;

    /* const now = moment();
    const { lastRefresh } = yield select(selectProfile);
    const maxCachedTime = process.env.CACHED_TIME;

    if (lastRefresh) {
      const diff = now.diff(lastRefresh, 'minutes');

      if (diff < maxCachedTime) {
        return yield put(profileActions.getProfileCached());
      }
    } */

    const { accessToken } = signInUserSession;

    if (!accessToken) return null;

    Sentry.setUser({ id: attributes['custom:internal_id'] });

    const { data } = yield call(
      getProfile,
      accessToken,
      attributes['custom:internal_id'],
      languageCode,
      adminRequestedStreetlibInternalId,
    );

    const { language } = data;

    const parsedLanguage = languageCode !== '' ? languageCode : language;

    const { data: onboard } = yield call(
      getProfileOnboard,
      accessToken,
      attributes['custom:internal_id'],
      parsedLanguage,
      adminRequestedStreetlibInternalId,
    );

    return yield put(profileActions.getProfileSuccess({ ...data, onboard }));
  } catch (err) {
    yield put(profileActions.getProfileFailure(err));

    return yield put(
      openFeedback({
        message: translations[languageCode].generic_retrieve_api_error,
        error: err,
      }),
    );
  }
}

function* patchProfileSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    const { attributes, signInUserSession, adminRequestedStreetlibInternalId } = yield select(
      selectAuth,
    );

    const { accessToken } = signInUserSession;

    const { data } = yield call(
      patchProfile,
      accessToken,
      attributes['custom:internal_id'],
      adminRequestedStreetlibInternalId,
      payload,
    );

    yield put(profileActions.patchProfileSuccess(data));
  } catch (err) {
    if (err.response) {
      if (err.response.data.errors) {
        yield put(profileActions.patchProfileFailure(err.response.data.errors[0]));
      } else {
        yield put(profileActions.patchProfileFailure(err.response.data));
      }
    } else {
      yield put(profileActions.patchProfileFailure(err));
    }

    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* patchLocalizationSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    const { attributes, signInUserSession, adminRequestedStreetlibInternalId } = yield select(
      selectAuth,
    );

    const { accessToken } = signInUserSession;

    const { data } = yield call(
      patchProfile,
      accessToken,
      attributes['custom:internal_id'],
      adminRequestedStreetlibInternalId,
      payload,
    );

    yield put(profileActions.patchLocalizationSuccess(data));
  } catch (err) {
    if (err.response) {
      if (err.response.data.errors) {
        yield put(profileActions.patchLocalizationFailure(err.response.data.errors[0]));
      } else {
        yield put(profileActions.patchLocalizationFailure(err.response.data));
      }
    } else {
      yield put(profileActions.patchLocalizationFailure(err));
    }

    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* sendLocalAuthSaga() {
  const payload = yield select(selectProfile);

  sendToNative(nativeActions.nativeLocalAuth(payload));
}

function sendProfileSaga({ payload }) {
  sendToNative(nativeActions.nativeUserProfile(payload.attributes));
}

function* activationSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    const { signInUserSession } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    yield call(getTokenActivation, accessToken, payload);

    yield put(profileActions.tokenActivationSuccess());
  } catch (err) {
    yield put(profileActions.tokenActivationFailure(err));

    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* patchAvatarSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    const { signInUserSession, attributes } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const body = {
      user: {
        avatar_base64: payload,
      },
    };

    const { data } = yield call(patchAvatar, accessToken, attributes['custom:internal_id'], body);

    yield put(profileActions.patchAvatarSuccess({ ...data }));
  } catch (err) {
    yield put(profileActions.patchAvatarFailure());

    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* deleteAvatarSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    const { signInUserSession, attributes } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    yield call(deleteAvatar, accessToken, attributes['custom:internal_id']);

    yield put(profileActions.deleteAvatarSuccess());
  } catch (err) {
    yield put(profileActions.deleteAvatarFailure());

    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* postNewsletterSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    const { signInUserSession, attributes } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const body = {
      user: {
        newsletter_list_extid: Number(process.env.NEWSLETTER_ID),
      },
    };

    const { data } = yield call(
      postNewsletter,
      accessToken,
      attributes['custom:internal_id'],
      body,
    );

    yield put(profileActions.postNewsletterSuccess(data));
  } catch (err) {
    if (err.response) {
      if (err.response.data.errors) {
        yield put(profileActions.postNewsletterFailure(err.response.data.errors[0]));
      } else {
        yield put(profileActions.postNewsletterFailure(err.response.data));
      }
    } else {
      yield put(profileActions.postNewsletterFailure(err));
    }

    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* deleteNewsletterSaga() {
  const { languageCode } = yield select(selectConfig);

  try {
    const { signInUserSession, attributes } = yield select(selectAuth);

    const { accessToken } = signInUserSession;

    const body = {
      user: {
        newsletter_list_extid: Number(process.env.NEWSLETTER_ID),
      },
    };

    const { data } = yield call(
      deleteNewsletter,
      accessToken,
      attributes['custom:internal_id'],
      body,
    );

    yield put(profileActions.deleteNewsletterSuccess(data));
  } catch (err) {
    if (err.response) {
      if (err.response.data.errors) {
        yield put(profileActions.deleteNewsletterFailure(err.response.data.errors[0]));
      } else {
        yield put(profileActions.deleteNewsletterFailure(err.response.data));
      }
    } else {
      yield put(profileActions.deleteNewsletterFailure(err));
    }

    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* postAcceptContractSaga({ payload }) {
  const { languageCode } = yield select(selectConfig);

  try {
    const { signInUserSession, attributes } = yield select(selectAuth);
    const { accessToken } = signInUserSession;

    yield call(postAcceptContract, accessToken, attributes['custom:internal_id'], payload);

    yield put(profileActions.postAcceptContractSuccess());

    // Dispatch per aggiornare il profilo
    yield put(profileActions.getProfileRequest());

    yield delay(8000);

    Router.replace('/services');
  } catch (err) {
    if (err.response) {
      if (err.response.data.errors) {
        yield put(profileActions.postAcceptContractFailure(err.response.data.errors[0]));
      } else {
        yield put(profileActions.postAcceptContractFailure(err.response.data));
      }
    } else {
      yield put(profileActions.postAcceptContractFailure(err));
    }

    yield put(
      openFeedback({
        message: translations[languageCode].generic_send_api_error,
        error: err,
      }),
    );
  }
}

function* userImpersonationSaga() {
  yield delay(1000);

  Router.reload();
}

export default function* watchProfile() {
  yield takeLatest(GET_PROFILE_REQUEST, getProfileSaga);
  yield takeEvery(PATCH_PROFILE_REQUEST, patchProfileSaga);
  yield takeLatest(NATIVE_LOCAL_AUTH, sendLocalAuthSaga);
  yield takeLatest(AUTH_SIGNIN_SUCCESS, sendProfileSaga);
  yield takeLatest(AUTH_SIGNIN_SUCCESS, getProfileSaga);
  yield takeLatest(AUTH_SIGNED_SUCCESS, getProfileSaga);
  yield takeLatest(SET_USER_IMPERSONATION, userImpersonationSaga);
  yield takeLatest(TOKEN_ACTIVATION_REQUEST, activationSaga);
  yield takeLatest(TOKEN_ACTIVATION_SUCCESS, getProfileSaga);
  yield takeLatest(PATCH_AVATAR_REQUEST, patchAvatarSaga);
  yield takeLatest(DELETE_AVATAR_REQUEST, deleteAvatarSaga);
  yield takeLatest(DELETE_AVATAR_SUCCESS, getProfileSaga);
  yield takeLatest(POST_NEWSLETTER_REQUEST, postNewsletterSaga);
  yield takeLatest(DELETE_NEWSLETTER_REQUEST, deleteNewsletterSaga);
  yield takeEvery(PATCH_LOCALIZATION_REQUEST, patchLocalizationSaga);
  yield takeLatest(SET_LANGUAGE, patchLocalizationSaga);
  yield takeLatest(POST_ACCEPT_CONTRACT_REQUEST, postAcceptContractSaga);
}