/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import _ from 'lodash';
import Router from 'next/router';
import moment from 'moment';
import translations from 'translations';
import configSelector from './config';

export const selectServices = createSelector(
  (state) => state.profile,
  configSelector,
  (profile, { languageCode }) => {
    const { payload, isGetWaiting } = profile;
    const { abilities, customer_type, onboard, company, country } = payload;
    const services = [];
    let mandatoryStepsFulfilled = true;

    const CONST_SHUFFLE = 'SHUFFLE_';

    if (!abilities || !onboard || isGetWaiting) return { services, isWaiting: isGetWaiting };

    const { steps } = onboard;

    const dashAgreement = _.find(steps, { name: 'dash_accepted_agreement' });

    steps
      .filter((step) => step.requirement === 'mandatory')
      .forEach((step) => {
        if (!step.fulfilled) {
          mandatoryStepsFulfilled = false;
        }
      });

    let servicesObj = {};
    abilities.forEach((ability) => {
      const {
        visible,
        platform: { name: platformName },
      } = ability;

      if (visible) {
        servicesObj = {
          ...servicesObj,
          [platformName]: ability,
        };
      }
    });

    let checkShufflePublish = true;

    Object.values(servicesObj)
      .filter(
        (service) =>
          !service.platform.name.startsWith(CONST_SHUFFLE) ||
          service.platform.name === 'SHUFFLE_PUBLISH',
      )
      .forEach((service) => {
        const {
          platform: { name: platformName },
        } = service;

        if (
          platformName === 'PUBLISH' ||
          platformName === 'SELFPUBLISH' ||
          platformName === 'DISTRIBUTION' ||
          platformName === 'POS' ||
          platformName === 'SHUFFLE_PUBLISH'
        ) {
          if (checkShufflePublish) {
            if (servicesObj.SHUFFLE_PUBLISH && servicesObj.SHUFFLE_PUBLISH.enabled) {
              checkShufflePublish = false;

              services.push({
                ...servicesObj.SHUFFLE_PUBLISH,
                enabled: false,
                customerType: customer_type,
                isNotSignable: !customer_type || !mandatoryStepsFulfilled,
                valid_from: servicesObj.SHUFFLE_PUBLISH.valid_from
                  ? moment(servicesObj.SHUFFLE_PUBLISH.valid_from).format('L')
                  : '',
              });
            } else if (servicesObj.DISTRIBUTION && servicesObj.DISTRIBUTION.enabled) {
              checkShufflePublish = false;

              services.push({
                ...servicesObj.DISTRIBUTION,
                customerType: customer_type,
                isNotSignable: !customer_type || !mandatoryStepsFulfilled,
                valid_from: servicesObj.DISTRIBUTION.valid_from
                  ? moment(servicesObj.DISTRIBUTION.valid_from).format('L')
                  : '',
              });
            } else if (servicesObj.PUBLISH && servicesObj.PUBLISH.enabled) {
              checkShufflePublish = false;

              services.push({
                ...servicesObj.PUBLISH,
                customerType: customer_type,
                isNotSignable: !customer_type || !mandatoryStepsFulfilled,
                valid_from: servicesObj.PUBLISH.valid_from
                  ? moment(servicesObj.PUBLISH.valid_from).format('L')
                  : '',
              });
            } else if (servicesObj.SELFPUBLISH && servicesObj.SELFPUBLISH.enabled) {
              checkShufflePublish = false;

              services.push({
                ...servicesObj.SELFPUBLISH,
                customerType: customer_type,
                isNotSignable: !customer_type || !mandatoryStepsFulfilled,
                valid_from: servicesObj.SELFPUBLISH.valid_from
                  ? moment(servicesObj.SELFPUBLISH.valid_from).format('L')
                  : '',
              });
            }
          }
        } else {
          services.push({
            ...service,
            valid_from: service.valid_from ? moment(service.valid_from).format('L') : '',
          });
        }
      });

    services.unshift({
      agreement_url: `https://streetlib-agreements.s3.eu-west-1.amazonaws.com/${languageCode === 'it' ? 'IT' : 'EN'
        }_StreetLib_Legal-Policy_202501.pdf`,
      enabled: dashAgreement.fulfilled,
      platform: {
        label: 'StreetLib',
        description: translations[languageCode].streetlib_description,
        url: '/home',
        name: 'DASH',
      },
    });

    if (servicesObj.SHUFFLE_WRITE && servicesObj.SHUFFLE_WRITE.enabled) {
      services.push({
        ...servicesObj.SHUFFLE_WRITE,
        enabled: false,
        valid_from: servicesObj.SHUFFLE_WRITE.valid_from
          ? moment(servicesObj.SHUFFLE_WRITE.valid_from).format('L')
          : '',
      });
    }

    if (company?.code === 'SL_IT' && country?.alpha2 === 'IT') {
      services.push({
        platform: {
          label: 'Ready',
          description: translations[languageCode].streetlib_ready_description,
          url: 'https://readybooks.io',
        },
        enabled: true,
        isNotSignable: false,
      });
    }

    return { services, isWaiting: isGetWaiting };
  },
);

const selectProfile = createSelector(
  (state) => state.profile,
  configSelector,
  (state) => state.auth.adminRequestedStreetlibInternalId,
  (profile, { languageCode }, adminRequestedStreetlibInternalId) => {
    const { localAuth, payload } = profile;

    let completitionPerc = 0;
    let isAdmin = adminRequestedStreetlibInternalId !== '';
    let isAdminDe = false;
    let isEnabled = true;
    let tokenEmail = null;
    let isEmailUpdated = false;
    let additionalSteps = null;
    let mandatorySteps = null;
    let mandatoryStepsFulfilled = true;
    let needsNewAgreement = false;
    let needsNewLegalPolicy = false;
    let redirectPath = null; // Nuova variabile per il reindirizzamento
    const guid = payload.guid;
    const customerType = payload.customer_type;
    const hasPROPlan = payload.active_plans?.some(plan => plan.code === 'US-PRO' && plan.status === 'active');

    if (!isAdmin && payload.abilities) {
      const { abilities } = payload;

      const dashAbility = abilities.find((ability) => ability.platform?.name === 'DASH');

      if (dashAbility) {
        isAdmin = dashAbility.role.toLowerCase() === 'admin';
        isAdminDe = dashAbility.roles.includes('ADMIN_DE');
        isEnabled = dashAbility.enabled;
      }

      const distributionAbility = abilities.find(
        (ability) => ability.platform?.name === 'DISTRIBUTION',
      );

      const shufflePublishAbility = abilities.find(
        (ability) => ability.platform?.name === 'SHUFFLE_PUBLISH',
      );

      if (
        distributionAbility &&
        shufflePublishAbility &&
        !distributionAbility.enabled &&
        !distributionAbility.visible &&
        distributionAbility.user_guid &&
        shufflePublishAbility.enabled &&
        shufflePublishAbility.visible
      ) {
        needsNewAgreement = true;
      }
    }

    if (payload.tokens) {
      const { tokens } = payload;
      tokenEmail = tokens.find((token) => token.action === 'user_update');
      isEmailUpdated = !!tokenEmail;
    }

    if (payload.onboard) {
      const {
        steps,
        completition: { current },
      } = payload.onboard;

      completitionPerc = current;

      const dashProfile = _.find(steps, { name: 'dash_valid_user_profile' });
      const dashAgreement = _.find(steps, { name: 'dash_accepted_agreement' });
      const paymentProfile = _.find(steps, { name: 'billing_payment_profile' });
      const billingProfile = _.find(steps, { name: 'billing_profile' });

      const onboardComplete =
        (dashAgreement.fulfilled &&
          dashProfile.fulfilled &&
          paymentProfile.fulfilled &&
          billingProfile.fulfilled) ||
        false;

      // Check if user is not admin and has not completed onboarding
      if (
        !isAdmin &&
        !isAdminDe &&
        !onboardComplete &&
        ![
          '/profile',
          '/services',
          '/enable-service',
          '/billing/profile',
          '/billing/payment-account',
          '/billing/summary-billing-profile',
          '/billing/summary-payment-account',
          '/billing/profiles',
          '/onboarding',
        ].includes(window.location.pathname)
      ) {
        redirectPath = '/onboarding';
      }

      if (needsNewAgreement) {
        redirectPath = '/enable-service?id=SHUFFLE_PUBLISH';
      }

      if (!dashAgreement.fulfilled) {
        needsNewLegalPolicy = true;
        redirectPath = '/enable-service?id=DASH';
      }

      // SET mandatory steps
      steps
        .filter((step) => step.requirement === 'mandatory')
        .forEach((step) => {
          if (!step.fulfilled) {
            mandatoryStepsFulfilled = false;
          }

          if (step.name === 'dash_accepted_agreement') {
            step.agreement = `https://streetlib-agreements.s3.eu-west-1.amazonaws.com/${languageCode === 'it' ? 'IT' : 'EN'
              }_StreetLib_Legal-Policy_202501.pdf`;
          }

          mandatorySteps = {
            ...mandatorySteps,
            [step.name]: step,
          };
        });

      // SET additional steps
      steps
        .filter((step) => step.requirement === 'optional')
        .forEach((step) => {
          additionalSteps = {
            ...additionalSteps,
            [step.name]: step,
          };
        });
    }

    return {
      localAuth,
      payload: {
        ...payload,
        avatar: payload.avatar,
        country_id: payload.country ? payload.country.id : '',
        hasPROPlan,
      },
      guid,
      customerType,
      isAdmin,
      isAdminDe,
      isEnabled,
      isEmailUpdated: !!isEmailUpdated,
      newEmail: tokenEmail ? tokenEmail.data.email : null,
      completitionPerc,
      additionalSteps,
      mandatorySteps,
      mandatoryStepsFulfilled,
      needsNewAgreement,
      needsNewLegalPolicy,
      redirectPath,
    };
  },
);

export default selectProfile;
