import axios from 'axios';
import base64 from 'base-64';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development' ? `${process.env.API_URL}/public` : process.env.API_URL,
  json: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const verifyToken = (accessToken, status) =>
  api.post('/verify-token', { token: accessToken, status });

export const refreshToken = (accessToken) => api.post('/refresh-token', { token: accessToken });

export const getProfile = (jwt, internalId, locale, adminRequestedStreetlibInternalId) =>
  api.get(
    `/users/${base64.encode(internalId)}?locale=${locale}${adminRequestedStreetlibInternalId
      ? `&adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}`
      : ''
    }`,
    {
      headers: { Authorization: jwt },
    },
  );

export const getProfileOnboard = (jwt, internalId, locale, adminRequestedStreetlibInternalId) =>
  api.get(
    `/users/${base64.encode(internalId)}/onboard?locale=${locale}${adminRequestedStreetlibInternalId
      ? `&adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}`
      : ''
    }`,
    {
      headers: { Authorization: jwt },
    },
  );

export const postAcceptContract = async (jwt, internalId, body) => {
  const encodedId = base64.encode(internalId);
  console.log('body', body);

  if (body.service === 'DASH') {
    // Effettua la richiesta PATCH per DASH
    const url = `/users/${encodedId}/register`;

    const payload = {
      user: {
        platform: 'DASH',
        user_guid: body.guid || '', // Assicuriamoci che user_guid sia presente
      },
    };

    return api.patch(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt, // Se il JWT è necessario
      },
    });
  }

  // Default: POST per SHUFFLE e SHUFFLE_WRITE
  let action = 'shuffle';
  let payload = body;

  if (body.service === 'SHUFFLE_WRITE') {
    action = 'multi_platform_shuffle';
    payload = { platform: 'SHUFFLE_WRITE' };
  }

  return api.post(`/users/${encodedId}/${action}`, payload, {
    headers: { Authorization: jwt },
  });
};

export const getBillingDocuments = (jwt, billingId, adminRequestedStreetlibInternalId, params) =>
  api.get(`/bill/v4/customers/${billingId}/invoiceDocuments`, {
    headers: { Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
      ...params,
    },
  });

export const getBillingProfiles = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
) =>
  api.get(`/bill/v4/customers/${billingId}/billingProfiles`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const getBillingProfile = (
  languageCode,
  jwt,
  billingId,
  pid,
  adminRequestedStreetlibInternalId,
) =>
  api.get(`/bill/v4/customers/${billingId}/billingProfiles/${pid}`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const getBillingSummary = (jwt, billingId, adminRequestedStreetlibInternalId) =>
  api.get(`/bill/v4/customers/${billingId}/invoiceDocuments/summary`, {
    headers: { Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const getCount = (jwt, params) =>
  api.get(`/analytics/count?${params}`, {
    headers: { Authorization: jwt },
  });

export const getHistogram = (jwt, params) =>
  api.get(`/analytics/histogram?${params}`, {
    headers: { Authorization: jwt },
  });

export const getAggr = (jwt, params) =>
  api.get(`/analytics/aggs?${params}`, {
    headers: { Authorization: jwt },
  });

export const getUnits = (jwt, params) =>
  api.get(`/analytics/search?${params}`, {
    headers: { Authorization: jwt },
  });

export const getHistory = (jwt, internalId, adminRequestedStreetlibInternalId) =>
  api.get(
    `/users/${base64.encode(internalId)}/history${adminRequestedStreetlibInternalId
      ? `?adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}`
      : ''
    }`,
    {
      headers: { Authorization: jwt },
    },
  );

export const postDownloadJob = (jwt, params, payload) =>
  api.post(`/queues/analytics/jobs?${params}`, payload, {
    headers: { Authorization: jwt },
  });

export const getDownloadJob = (jwt, key) =>
  api.get(`/queues/analytics/jobs/${key}`, {
    headers: { Authorization: jwt },
  });

export const getWildcard = (
  jwt,
  {
    adminRequestedStreetlibInternalId,
    dateFrom,
    dateTo,
    currency,
    offset,
    term,
    aggrTerm,
    wildcard,
  },
) =>
  api.get(
    `/analytics/aggs?currency=${currency}&dateFrom=${dateFrom}&dateTo=${dateTo}&size=100&offset=${offset}&term=${aggrTerm}&filters=${term}=${wildcard}${adminRequestedStreetlibInternalId
      ? `&adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}`
      : ''
    }`,
    {
      headers: { Authorization: jwt },
    },
  );

export const getWarning = () => api.get('/notifications?enabled=true');

export const getNews = (languageCode) =>
  api.get(
    `https://streetlib.ghost.io/ghost/api/v3/content/posts/?limit=3&filter=tag:hash-${languageCode}&include=tags&key=${process.env.GHOST_ID}`,
  );

export const getSettings = (jwt, internalId, adminRequestedStreetlibInternalId) =>
  api.get(
    `/users/${base64.encode(internalId)}/settings${adminRequestedStreetlibInternalId
      ? `?adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}`
      : ''
    }`,
    {
      headers: { Authorization: jwt },
    },
  );

export const putPreset = (jwt, internalId, adminRequestedStreetlibInternalId, body) =>
  api.patch(
    `/users/${base64.encode(internalId)}${adminRequestedStreetlibInternalId
      ? `?adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}`
      : ''
    }`,
    body,
    {
      headers: { Authorization: jwt },
    },
  );

export const getComparedTo = (jwt, dateFrom, dateTo, compare, dateRange) =>
  api.get(
    `/analytics/aggs?compare=${compare}&size=1&dateTo=${dateTo}&dateFrom=${dateFrom}&dateRange=${dateRange}&version=7`,
    {
      headers: { Authorization: jwt },
    },
  );

export const getCountries = (locale) => {
  const newLocale = locale === 'pt' || locale === 'de' ? 'en' : locale;
  return api.get(`/countries?locale=${newLocale}`);
};

export const getTimezones = (jwt) =>
  api.get('/timezones', {
    headers: { Authorization: jwt },
  });

export const getTokenActivation = (jwt, token) =>
  api.get(`/tokens/${token}/activate`, {
    headers: { Authorization: jwt },
  });

export const patchProfile = (jwt, internalId, adminRequestedStreetlibInternalId, body) =>
  api.patch(
    `/users/${base64.encode(internalId)}${adminRequestedStreetlibInternalId
      ? `?adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}`
      : ''
    }`,
    body,
    {
      headers: { Authorization: jwt },
    },
  );

export const patchPassword = (jwt, adminRequestedStreetlibInternalId, body) =>
  api.patch(
    `/passwords/change${adminRequestedStreetlibInternalId
      ? `?adminRequestedStreetlibInternalId=${adminRequestedStreetlibInternalId}`
      : ''
    }`,
    body,
    {
      headers: { Authorization: jwt },
    },
  );

export const patchAvatar = (jwt, internalId, body) =>
  api.patch(`/users/${base64.encode(internalId)}/avatar`, body, {
    headers: { Authorization: jwt },
  });

export const deleteAvatar = (jwt, internalId) =>
  api.delete(`/users/${base64.encode(internalId)}/avatar`, {
    headers: { Authorization: jwt },
  });

export const postNewsletter = (jwt, internalId, body) =>
  api.post(`/users/${base64.encode(internalId)}/newsletter`, body, {
    headers: { Authorization: jwt },
  });

export const deleteNewsletter = (jwt, internalId, body) =>
  api.delete(`/users/${base64.encode(internalId)}/newsletter`, {
    headers: { Authorization: jwt },
    data: body,
  });

export const getStores = () => api.get('/analytics/stores');

export const getReportStatuses = (languageCode, jwt, adminRequestedStreetlibInternalId) =>
  api.get(`/publish/analytics/reports/sales/statuses`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const getInvoice = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
  pid,
  billing_profile_pid,
) =>
  api.get(`/bill/v4/customers/${billingId}/invoiceDocuments/${pid}`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
      billing_profile_pid,
    },
  });

export const patchBillingProfile = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
  pid,
  body,
) =>
  api.patch(`/bill/v4/customers/${billingId}/billingProfiles/${pid}`, body, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const postBillingProfile = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
  body,
) =>
  api.post(`/bill/v4/customers/${billingId}/billingProfiles`, body, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const deleteBillingProfile = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
  profileId,
) =>
  api.delete(`/bill/v4/customers/${billingId}/billingProfiles/${profileId}`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const getPaymentMethods = (jwt) =>
  api.get('/bill/v4/paymentMethods', {
    headers: { Authorization: jwt },
  });

export const getPaymentAccounts = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
) =>
  api.get(`/bill/v4/customers/${billingId}/paymentAccounts`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const getPaymentAccount = (
  languageCode,
  jwt,
  billingId,
  pid,
  adminRequestedStreetlibInternalId,
) =>
  api.get(`/bill/v4/customers/${billingId}/paymentAccounts/${pid}`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const postPaymentAccount = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
  body,
) =>
  api.post(`/bill/v4/customers/${billingId}/paymentAccounts`, body, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const patchPaymentAccount = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
  paymentAccountId,
  body,
) =>
  api.patch(`/bill/v4/customers/${billingId}/paymentAccounts/${paymentAccountId}`, body, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const deletePaymentAccount = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
  paymentAccountId,
) =>
  api.delete(`/bill/v4/customers/${billingId}/paymentAccounts/${paymentAccountId}`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      adminRequestedStreetlibInternalId,
    },
  });

export const getSubdivisions = (languageCode, jwt, countryCode) =>
  api.get(`/bill/v4/countries/${countryCode}/subdivisions`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
  });

export const getTaxDocuments = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
  page,
  per_page,
) =>
  api.get(`/bill/v4/customers/${billingId}/taxDocuments`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: {
      page,
      per_page,
      adminRequestedStreetlibInternalId,
    },
  });

export const downloadTaxDocument = (languageCode, jwt, adminRequestedStreetlibInternalId, path) =>
  axios.get(path, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const approveInvoiceDocument = (
  languageCode,
  jwt,
  billingId,
  adminRequestedStreetlibInternalId,
  pid,
  body,
) =>
  api.post(`/bill/v4/customers/${billingId}/invoiceDocuments/${pid}/approve`, body, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const downloadInvoice = (languageCode, jwt, adminRequestedStreetlibInternalId, path) =>
  axios.get(path, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const downloadReportInvoice = (
  languageCode,
  jwt,
  adminRequestedStreetlibInternalId,
  billingId,
  pid,
  format,
) =>
  api.get(`/bill/v4/customers/${billingId}/invoiceDocuments/${pid}/analyticsReport/${format}`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const getTaxInterviews = (
  languageCode,
  jwt,
  adminRequestedStreetlibInternalId,
  billingId,
  page,
  per_page,
) =>
  api.get(`/bill/v4/customers/${billingId}/taxDocuments`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { page, per_page, adminRequestedStreetlibInternalId },
  });

export const postTaxInterview = (
  languageCode,
  jwt,
  adminRequestedStreetlibInternalId,
  billingId,
  body,
) =>
  api.post(`/bill/v4/customers/${billingId}/taxDocuments`, body, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const patchTaxInterview = (
  languageCode,
  jwt,
  adminRequestedStreetlibInternalId,
  billingId,
  pid,
  body,
) =>
  api.patch(`/bill/v4/customers/${billingId}/taxDocuments/${pid}`, body, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const downloadTaxInterview = (
  languageCode,
  jwt,
  adminRequestedStreetlibInternalId,
  billingId,
  pid,
) =>
  api.get(`/bill/v4/customers/${billingId}/taxDocuments/${pid}/downloadLink`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const getCountryWithheldDetails = (languageCode, jwt, countryCode) =>
  api.get(`/bill/v4/countryWithhelds/${countryCode}`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
  });

export const isWithrawAvailable = (
  languageCode,
  jwt,
  adminRequestedStreetlibInternalId,
  billingId,
) =>
  api.get(`/bill/v4/customers/${billingId}/isWithrawAvailable`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const getWithdrawals = (languageCode, jwt, adminRequestedStreetlibInternalId, billingId) =>
  api.get(`/bill/v4/customers/${billingId}/withdrawals`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const getLastWithdrawal = (
  languageCode,
  jwt,
  adminRequestedStreetlibInternalId,
  billingId,
) =>
  api.get(`/bill/v4/customers/${billingId}/withdrawals/last`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const postWithdrawal = (
  languageCode,
  jwt,
  adminRequestedStreetlibInternalId,
  billingId,
  body,
) =>
  api.post(`/bill/v4/customers/${billingId}/withdrawals`, body, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const checkPayeeId = (languageCode, jwt, payeeId) =>
  api.get(`/bill/v4/misc/payoneer/checkPayee?payeeIdentifier=${payeeId}`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
  });

export const postPayoneerRegistration = (
  languageCode,
  jwt,
  adminRequestedStreetlibInternalId,
  body,
) =>
  api.post(`/bill/v4/misc/payoneer/registrationLink`, body, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { adminRequestedStreetlibInternalId },
  });

export const checkTin = (languageCode, jwt, countryCode, tin, type = null) =>
  api.get(`/bill/v4/misc/tinCheck`, {
    params: { country_code: countryCode, tin, typology: type },
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
  });

export const getAnalyticsFilters = (
  languageCode,
  jwt,
  adminRequestedStreetlibInternalId,
  type,
  params,
) =>
  api.get(`/publish/analytics/publish/${type}`, {
    headers: { 'Accept-Language': languageCode, Authorization: jwt },
    params: { ...params, adminRequestedStreetlibInternalId },
  });
